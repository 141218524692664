<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-12 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('order_successfully_placed') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li class="list-group-item">
						<div class="row">
							<div
								id="content"
								class="col-sm-12 text-center"
								style="margin: 30px 0 60px;">
								<i class="fas fa-check-circle fa-10x success-icon" />
								<div class="success-message margin-20">
									<h3
										class="mt-4"
										v-html="translate('checkout_success_subtittle')" />
									<hr>
									<p v-html="translate('checkout_success_body', {support:companyEmail, support1:companyEmail})" />
								</div>
								<div class="clearfix text-center">
									<div class="container">
										<div class="col-xs-12">
											<div class="text-center">
												<b-button
													variant="primary"
													style="width: 200px"
													:class="!['xs'].includes(windowWidth) ? 'mr-4' : 'mb-2'"
													@click="$router.push({ name: getRedirectName('Store') })">
													{{ translate('go_to_store') }}
												</b-button>
												<b-button
													variant="primary"
													style="width: 200px"
													@click="$router.push({ name: 'Login' })">
													{{ translate('go_to_login') }}
												</b-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-4 ml-auto my-4" />
		</div>
	</div>
</template>

<script>
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import AgencySite from '@/mixins/AgencySite';
import { AGENCY_REPLICATED_SITE_PATHS } from '@/settings/Replicated';
import { ORDER_VERIFICATION_PHONES } from '@/settings/Purchase';
import { Purchase, Validations } from '@/translations';

export default {
	name: 'CheckoutSuccess',
	messages: [Purchase, Validations],
	mixins: [DocumentTitle, WindowSizes, AgencySite],
	props: {
		// paymentMethodName: {
		// 	type: String,
		// 	default: '',
		// },
		verificationRequired: {
			type: Boolean,
			default: false,
		},
		ecommercePath: {
			type: String,
			default: '',
		},
		orderId: {
			type: String,
			default: '',
		},
		orderTotal: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
		};
	},
	mounted() {
		// if (this.paymentMethodName === 'checkmo') {
		// 	this.alert.success(this.translate('cash_payments_popup_title'), this.translate('cash_payments_popup_description', { displayedLink: this.companyEmail, supportLink: this.companyEmail }), true);
		// }
		if (this.verificationRequired) {
			const phoneNumbers = [].concat(ORDER_VERIFICATION_PHONES);
			const last = phoneNumbers.pop();
			const phoneList = phoneNumbers.length > 0
				? `${phoneNumbers.join(', ')} ${this.translate('or')} ${last}`
				: last;

			this.alert.success(this.translate('verbal_verification_popup_title'), this.translate('verbal_verification_popup_description', {
				companyName: process.env.VUE_APP_TITLE,
				phoneNumber: phoneList,
			}), true);
		}

		this.trackPurchaseEvent();

		const replicatedPage = this.$replicated.replicatedPage();
		const normalCustomCart = replicatedPage === 'checkout';
		const customCartExists = localStorage.getItem('custom_cart_uuid') || false;
		if (normalCustomCart && customCartExists) {
			// If replicated page is 'store' (no discounts store) or 'checkout', the custom cart id is deleted and users stays in the same replicated site
			localStorage.removeItem('custom_cart_uuid');
		}
	},
	methods: {
		trackPurchaseEvent() {
			// TODO: figure out how to send test events with pixel and replace this
			if (this.ecommercePath === AGENCY_REPLICATED_SITE_PATHS.meta && process.env.VUE_APP_ENV !== 'production') {
				console.log('Would trigger purchase event with Meta Pixel in Production. Omitted in development. Event Data:');
				console.log({
					value: this.orderTotal,
					currency: 'USD',
					eventID: `purchase_${this.orderId}`,
				});
			}

			if (this.ecommercePath === AGENCY_REPLICATED_SITE_PATHS.meta && process.env.VUE_APP_ENV === 'production') {
				// eslint-disable-next-line no-undef
				fbq(
					'track',
					'Purchase',
					{
						value: this.orderTotal,
						currency: 'USD',
					},
					{
						eventID: `purchase_${this.orderId}`,
					},
				);
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			const checkoutRedirect = vm.getRedirectName('CheckoutConfirmation');
			if (from.name === checkoutRedirect && to.query.wallet_address) {
				const cryptoRedirect = vm.getRedirectName('CryptoPaymentsDetails');
				vm.$router.replace({ name: cryptoRedirect, params: { wallet_address: to.query.wallet_address } });
			} else if (from.name !== checkoutRedirect) {
				vm.$router.replace({ name: 'Login' });
			}
		});
	},
};
</script>
<style scoped>
.success-icon::before {
	color: #63D13E !important;
}
</style>
